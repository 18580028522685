<template>
  <div class="container mt-5 login-page">
    <h1 class="font-weight-bold">Space Admin Login</h1>
    <a-form layout="vertical">
      <a-form-item
        label="username or email"
        has-feedback
        :validate-status="validate.identity ? 'error' : ''"
      >
        <a-input
          v-model="identity"
          name="identity"
          placeholder="username or email"
        />
        <validate-error
          v-if="validate.identity"
          :errors="validate.identity"
        />
      </a-form-item>
      <a-form-item
        label="password"
        has-feedback
        :validate-status="validate.password ? 'error' : ''"
      >
        <a-input
          v-model="password"
          type="password"
          placeholder="password"
          @keyup.enter="login"
        />
        <validate-error
          v-if="validate.password"
          :errors="validate.password"
        />
      </a-form-item>
      <a-form-item>
        <router-link :to="{ name: 'reset-password' }" class="forgot-password">
          forgot password?
        </router-link>
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          :loading="isLoading"
          :disabled="!canSubmit"
          @click="login"
        >
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import AdminService from "@/services/admin.service";
import validationError from "@/helpers/display-error";
import ValidateError from "@/components/common/ValidateError";
import { call } from "vuex-pathify";
export default {
  components: { ValidateError },
  data() {
    return {
      identity: '',
      password: '',
      validate: {},
      isLoading: false
    };
  },
  computed: {
    canSubmit() {
      return !!this.identity && this.password.length >= 8;
    }
  },
  methods: {
    loadProfile: call("auth/loadProfile"),
    async login() {
      this.isLoading = true;
      this.validate = {};
      const { identity, password } = this;
      const { status, validation } = await AdminService.login({
        identity,
        password
      });
      this.isLoading = false;
      if (status === 201) {
        this.$message.success("Login Success");
        await this.loadProfile();
        this.$router.push("/my-organization");
        return;
      }
      const { type, validation: validate } = await validationError(validation);

      if (type === "string") {
        this.$message.error(validate);
      } else {
        this.validate = validate;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.forgot-password {
  color: $primary-color;
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}
.login-page {
  max-width: 540px;
}
</style>
